// This is all you.
import Swup from 'swup';
import SwupGaPlugin from '@swup/ga-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';

function navClasses() {
    var all_links = document.getElementById("menu").getElementsByTagName("a"),
        i=0, len=all_links.length,
        full_path = location.href.split('#')[0]; //Ignore hashes?

    // Loop through each link.
    for(; i<len; i++) {
        if(all_links[i].href.split("#")[0] == full_path) {
            all_links[i].className += " active";
        }
    }
}
function navClassesReset() {
    var navElements = document.querySelectorAll('#menu a');
      //looping through each anchor element
      navElements.forEach(function(element){
           //adding click event on each anchor element
                 element.classList.remove('active'); //add class to current click element
     });
}

function closeNav() {
    const but = document.getElementById('navToggle');
    const menu = document.getElementById('menu');

    but.classList.remove('is-active');
    menu.classList.remove('is-active');

}

function init() {
    var el = document.getElementById('navToggle');
    const menu = document.getElementById('menu');
    el.onclick = function() {
      el.classList.toggle('is-active');
      menu.classList.toggle('is-active');
    }
    navClasses();
}

function unload() {
    navClassesReset();
    closeNav();
    console.log("Goodbye Steve");
}

const swup = new Swup({
  plugins: [
    new SwupGaPlugin(),
    new SwupBodyClassPlugin(),
    new SwupPreloadPlugin(),
    new SwupScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: false
    })
  ]

}); // only this line when included with script tag

swup.on('contentReplaced', init);
swup.on('willReplaceContent', unload);

init();
